import React from 'react';
import {Text, TextButton, TextButtonPriority} from 'wix-ui-tpa/cssVars';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {useControllerProps} from '../../Widget/ControllerContext';
import {getTotal} from '../../../../domain/utils/total.util';
import {classes} from './FoldableSummarySection.st.css';
import ErrorIcon from 'wix-ui-icons-common/on-stage/Error';
import {isLineItemErrorViolationExist} from '../LineItem/LineItem.utils';
import ChevronUp from 'wix-ui-icons-common/on-stage/ChevronUp';
import ChevronDown from 'wix-ui-icons-common/on-stage/ChevronDown';

export enum FoldableSummarySectionDataHook {
  root = 'FoldableSummarySectionDataHooks.root',
  content = 'FoldableSummarySectionDataHook.content',
  contentWrapper = 'FoldableSummarySectionDataHook.contentWrapper',
  total = 'FoldableSummarySectionDataHook.total',
  totalWrapper = 'FoldableSummarySectionDataHook.totalWrapper',
  errorIcon = 'FoldableSummarySectionDataHook.errorIcon',
}

export interface FoldableSummarySectionProps {
  onClick: () => void;
  isOpen: boolean;
}

export const FoldableSummarySection = ({onClick, isOpen}: FoldableSummarySectionProps) => {
  const localeKeys = useLocaleKeys();
  const {
    checkoutStore: {checkout, shouldShowViolations, applyCouponError, applyGiftCardError, hasPartialOutOfStockError},
  } = useControllerProps();
  const shouldShowErrorIcon =
    !!checkout.errors.noItemsError ||
    (shouldShowViolations && isLineItemErrorViolationExist(checkout.violations)) ||
    applyCouponError ||
    applyGiftCardError ||
    hasPartialOutOfStockError;
  return (
    <div
      data-hook={FoldableSummarySectionDataHook.root}
      className={classes.root}
      onClick={onClick}
      role={'button'}
      tabIndex={0}
      onKeyDown={onClick}>
      <TextButton
        data-hook={FoldableSummarySectionDataHook.contentWrapper}
        priority={TextButtonPriority.secondary}
        prefixIcon={
          shouldShowErrorIcon ? (
            <ErrorIcon
              data-hook={FoldableSummarySectionDataHook.errorIcon}
              className={classes.errorIcon}
              aria-label={localeKeys.checkout.mobile.alertIcon.ariaLabel()}
            />
          ) : undefined
        }>
        <Text data-hook={FoldableSummarySectionDataHook.content}>
          {isOpen
            ? localeKeys.checkout.mobile.hideOrderSummary.label({number: checkout.numberOfItems})
            : localeKeys.checkout.mobile.showOrderSummary.label({number: checkout.numberOfItems})}
        </Text>
      </TextButton>
      <TextButton
        data-hook={FoldableSummarySectionDataHook.totalWrapper}
        priority={TextButtonPriority.secondary}
        className={classes.totalWrapper}
        suffixIcon={isOpen ? <ChevronUp /> : <ChevronDown />}>
        <Text data-hook={FoldableSummarySectionDataHook.total} className={classes.total}>
          {getTotal(checkout)}
        </Text>
      </TextButton>
    </div>
  );
};
